<template>
  <div class="fx-center full-height full-width">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :opacity="0.97" :z-index="2147480000"></loading>
    <div id="forgot-password" class=" ">
      <span class="c-account-box__logo">
        <img class="mob-logo" :src="baseUrl + 'img/logo.png'" :alt="app_name + ' logo'" />
      </span>
      <div class="c-account-box__headline">Verificação de e-mail</div>
      <div class="c-account-box__content">
        <form class="c-form-account">
          <div class="c-form-account__row js-valid-row">
            <div class="">
              <label class="c-ui-input c-ui-input--account-login">
                <input
                  class="c-ui-input__field c-ui-input__field--right-placeholder"
                  type="text"
                  v-model="email"
                  placeholder="email"
                  tabindex="1"
                />
              </label>
            </div>
          </div>
          <div class="c-form-account__row c-form-account__row--submit">
            <div class="">
              <button class="btn clickable" type="button" @click="doResend">Reenviar E-mail</button>
            </div>
          </div>
          <h1 v-if="message && message.length > 0">{{ message }}</h1>
        </form>
        <router-link :to="{ name: 'login'}">
          <i class="mg-2 fas fa-chevron-left"></i>
          Voltar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { apiBaseUrl } from "@/services/api.js";
import util from "@/services/util";
import { debounce } from "debounce";
import HeaderBuilder from "@/services/header-builder.js";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet


export default {
  name: "ResendVerifyEmail",
  components: {
    Loading,
  },
  data: function() {
    return {
      isLoading: false,
      message: null,
      email: "",
      app_name: process.env.VUE_APP_NAME,
      tenant_id: process.env.VUE_APP_TENANT_ID,
      baseUrl: process.env.BASE_URL,
    };
  },
  mounted() {
    this.$root.$emit("showOnlyRouter");
  },
  beforeDestroy() {
    this.$root.$emit("showWholeApp");
  },
  methods: {
    doResend: debounce(
      function() {
        this.isLoading = true;
        axios
          .post(`${apiBaseUrl}/api/email/resend`, { email: this.email }, { headers: HeaderBuilder.build() })
          .then(
            response => {
              this.message =
                "Cheque seu e-mail e clique no botão para verificar seu e-mail. Não se esqueça de verificar a caixa de spam!";
            },
            errorResponse => {
              this.$notify({
                type: "error",
                title: "Erro",
                duration : 24000,
                text: util.stringifyAxiosError(errorResponse)
              });
              this.message = "Erro ao enviar e-mail de verificação!";
            }
          ).finally(() => {
            this.isLoading = false;
          });
      },
      1000,
      true
    )
  }
};
</script>
